import Mustache from "mustache"

export const useSiteSeo = (view = {}) => {
  const route = useRoute()
  const currentSite = inject("currentSite")

  const site = computed(() => {
    if (currentSite.value.alias) {
      return currentSite.value.alias
    } else {
      return currentSite.value
    }
  })

  const template = computed(() => {
    const currentPath = route.path
    const templates = site.value.seoSitePageSettings

    // Check for exact path match first
    if (templates[currentPath]) {
      return templates[currentPath]
    }

    // Check for wildcard matches
    for (const templatePath in templates) {
      if (templatePath.endsWith("*")) {
        const prefix = templatePath.slice(0, -1) // Remove the '*'
        if (currentPath.startsWith(prefix)) {
          return templates[templatePath]
        }
      }
    }

    // If no match found, return empty object
    return null
  })

  const decoratedView = computed(() => {
    return {
      siteGender: site.value.gender === "girls" ? "Girls" : "Guys",
      siteName: site.value.seoSiteName,
      siteNameWithTld: site.value.seoSiteNameWithTld,
      siteNameWithSpaces: site.value.seoSiteNameWithSpaces,
      siteNameWithoutSpaces: site.value.seoSiteNameWithoutSpaces,
      ...view,
    }
  })

  const title = computed(() => {
    if (template.value) {
      return Mustache.render(template.value.title, decoratedView.value)
    } else {
      return Mustache.render(
        view.defaultTitle || site.value.seoDefaultTitle,
        decoratedView.value,
      )
    }
  })

  const description = computed(() => {
    if (template.value) {
      return Mustache.render(template.value.description, decoratedView.value)
    } else {
      return Mustache.render(
        view.defaultDescription || site.value.seoDefaultDescription,
        decoratedView.value,
      )
    }
  })

  useHead({
    title,
    meta: [
      {
        name: "description",
        content: description,
      },
    ],
  })
}
